import './App.css';
import React, { useState, useEffect, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import useHistoryTracker from './utilities/customHooks/useHistoryTracker';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from './pages/ForgotPassword';
import TaskList from './pages/TaskList';
import CheckIn from './pages/CheckIn';
import ExpandedView from './pages/ExpandedView';
import VideoTutorial from './pages/VideoTutorial';
import Profile from './pages/Profile';

import Inventory from './pages/Inventory';

import Plants from './pages/Plants';
import CSM from './pages/CSM';
import Client from './pages/Client';
import ServiceProvider from './pages/ServiceProvider';
import PlantConfiguration from './pages/PlantConfiguration';
import WorkForce from './pages/WorkForce';
import EscalationLevel from './pages/EscalationLevel';

import FixedTasksConfig from './pages/FixedTasksConfig';
import AlertTaskConfig from './pages/AlertTaskConfig';
//import Reports from "./pages/Reports";

import FixedTaskMaster from './pages/FixedTaskMaster';
import AlertTaskMaster from './pages/AlertTaskMaster';
import Notifications from './components/Notification';
import ResetPassword from './pages/ResetPassword';
import ManpowerAvailability from './pages/ManpowerAvailability';
import CheckInForRO from './pages/CheckInForRO';
import Maintenance from './pages/Maintenance';
import EquipmentControl from './pages/EquipmentControl';

import { onMessageListener } from './firebaseInit';
import ReactNotificationComponent from './components/ReactNotification';
import OneTimeTask from './pages/OneTimeTask';
// import Reports2 from "./pages/Reports2";
//Testing
import WorkspaceConfig from './pages/WorkspaceConfig';
import WorkspaceConfigList from './pages/WorkspaceConfigList';
import StoreManagement from './pages/StoreManagement';
import StoreConfiguration from './pages/StoreConfiguration';
import StoreDetails from './pages/StoreDetails';
import DataCorrection from './components/DataCorrection/DataCorrection';
import LayoutVisualisationConfig from './pages/LayoutVisualisationConfig';
import LayoutVisualisationParent from './pages/LayoutVisualisationParent';
import WorkspaceAlertTaskMaster from './pages/WorkspaceAlertTaskMaster';
import WorkspaceEscalationConfig from './pages/WorkspaceEscalationConfig';
import WorkspaceFormulaConfiguration from './pages/WorkspaceFormulaConfig';
import WorkspaceAlertConfig from './pages/WorkspaceAlertConfig';
import FormulaConfiguration from './pages/FormulaConfiguration';
import DashboardPage from './pages/dashboardPage';
import DashboardConfig from './pages/dashboardConfig';
import PageConfig from './pages/pageConfig';
import EmailPage from './pages/EmailPage';
import DashboardA4PrintPage from './pages/dashboardA4PrintPage';
import DataInputv2 from './pages/DataInputv2';
import DataInputConfigurationv2 from './pages/DataInputConfigurationv2';
import Page404 from './pages/Page404';
import Ticketsv2 from './pages/Ticketsv2';
import DigitalPaaniTeam from './pages/digitalPaaniTeam';
import ManualAlertConfig from './pages/ManualAlertConfig';
import Sensors from './pages/Sensors';
import AboutPage from './pages/AboutPage';
import DataInputs from './pages/DataInputs';
import ChemicalLog from './pages/ChemicalLog';
import SolutionDetailsForChemical from './pages/SolutionDetailsForChemical';
import InventoryOptions from './pages/InventoryOptions';
import Solution from './pages/Solution';
import SolutionDetail from './pages/SolutionDetail';
import DosingConfiguration from './pages/DosingConfiguration';
import ChemicalComposition from './pages/ChemicalComposition';
import PlantVisualisationConfig from './pages/PlantVisualisationConfig';
import InventoryCategoryLogs from './pages/InventoryCategoryLogs';
import PlantVisualisationParent from './pages/PlantVisualisationParent';
import Home from './components/Home';
import TestPage from './pages/TestPage';
import BiDirectionalLogs from './pages/BiDirectionalLogs';
import DashboardPageList from './pages/DashboardPageList';
import PageConfigV2 from './pages/pageConfigV2';
import LoginV2 from './pages/LoginV2';
import ForgotPasswordV2 from './pages/ForgotPasswordV2';
import AIAnalysisConfig from './pages/AIAnalysisConfig';
import UnifiedPageConfig from './pages/UnifiedPageConfig';
import UnifiedDashboardPageList from './pages/UnifiedDashboardPageList';
import { ScrollToTop } from './utilities/ScrollToTop';
import DataBreakForPlant from './pages/DataBreakView';
import DataBreakDetailsForSinglePlant from './pages/DataBreakDetailsForSinglePlant';
import DnDUnifiedPageConfig from './pages/DnDUnifiedPageConfig';
import UnifiedDashboardPage2 from './pages/UnifiedDashboardPage2';
import PermissionList from './pages/user-management/permissions/permissionList';
import GroupCreatePage from './pages/userGroup/component/GroupCreatePage';
import WorkspaceListPage from './pages/workspace-management/component/WorkspaceListPage';
import WorkspaceCreatePage from './pages/workspace-management/component/WorkspaceCreatePage';
import RoleListPage from './pages/user-management/role/component/RoleListPage';
import RoleCreatePage from './pages/user-management/role/component/RoleCreatePage';
import GroupEditPage from './pages/userGroup/component/GroupEditPage';
import UserCreatePage from './pages/user-management/components/UserCreatePage';
import RoleEditPage from './pages/user-management/role/component/RoleEditPage';
import UserGroupListPage from './pages/userGroup/component/GroupListPage';
import UserListPage from './pages/user-management/components/UserListPage';
import AuthorizationFailed from './core-components/theme/components/DialogBox/AuthorizationFailed';
import WelspunDemo from './pages/WelspunDemo';
import UserEditPage from './pages/user-management/components/UserEditPage';
// import { FormulaCreation, FormulaListing } from './pages/formulas';

const FormulaCreation = React.lazy(() => import('./pages/formulas').then(module => ({ default: module.FormulaCreation })));
const FormulaListing = React.lazy(() => import('./pages/formulas').then(module => ({ default: module.FormulaListing })));

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  //@ts-ignore
  const historyStack = useHistoryTracker();

  useEffect(() => {
    document.addEventListener('scroll', function (e) {
      if (show) setShow(false);
    });
  }, []);

  onMessageListener()
    .then(payload => {
      setShow(true);
      // console.log(payload);
      if (
        payload.notification.title &&
        payload.notification.body &&
        payload.notification.title !== '' &&
        payload.notification.body !== ''
      ) {
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      }

      // console.log(payload);
    })
    .catch(err => console.log('failed: ', err));

  return (
    <>
      <Routes>
        {ScrollToTop()}
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/Login" element={<LoginV2 />} />
        <Route path="/createUserGroup" element={<GroupCreatePage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="/editUserGroup/:id" element={<GroupEditPage />} />
        <Route path="/userGroupList" element={<UserGroupListPage />} />
        {/* This need to be removed before merging into list */}
        <Route path="/authorizationFailed" element={<AuthorizationFailed />} />{' '}
        <Route path="/workspaceList" element={<WorkspaceListPage />} />
        <Route path="/createWorkspace/:id" element={<WorkspaceCreatePage />} />
        <Route path="/editWorkspace/:id" element={<WorkspaceCreatePage />} />
        <Route path="/createUser" element={<UserCreatePage />} />
        <Route path="/editUser/:id" element={<UserEditPage />} />
        <Route path="/userList" element={<UserListPage />} />
        <Route path="/rolelist" element={<RoleListPage />} />
        <Route path="/createrole" element={<RoleCreatePage />} />
        <Route path="/editRole/:id" element={<RoleEditPage />} />
        <Route path="/permission" element={<PermissionList />} />
        {/* Protected Routes */}
        <Route>
          <Route path="/PlantConfig/:id" element={<PlantConfiguration />} />
          <Route path="/fixedTasksConfig/:id" element={<FixedTasksConfig />} />
          <Route path="/alertTasksConfig/:id" element={<AlertTaskConfig />} />
          <Route path="/formulaConfiguration/:id" element={<FormulaConfiguration />} />
          <Route path="/StoreManagement/:storeId" element={<StoreManagement />} />
          <Route path="/DataInputConfigv2/:id" element={<DataInputConfigurationv2 />} />
          <Route path="/manualAlertsConfig/:id" element={<ManualAlertConfig />} />
          <Route path="/PlantVisualisationConfig/:plantId" element={<PlantVisualisationConfig />} />
          <Route path="/DosingConfiguration/:plantId" element={<DosingConfiguration />} />

          <Route path="/WorkspaceEscalationConfig/:id" element={<WorkspaceEscalationConfig />} />
          <Route path="/WorkspaceAlertConfig/:id" element={<WorkspaceAlertConfig />} />

          <Route path="/WorkspaceFormulaConfig/:id" element={<WorkspaceFormulaConfiguration />} />
          <Route path="/databreak" element={<DataBreakForPlant />}></Route>
          <Route path="/databreakdetails/:assetId" element={<DataBreakDetailsForSinglePlant />} />
        </Route>
        <Route>
          <Route path="/clients" element={<Client />} />
          <Route path="/serviceproviders" element={<ServiceProvider />} />
          <Route path="/csm" element={<CSM />} />
          <Route path="/fixedTaskMaster" element={<FixedTaskMaster />} />
          <Route path="/alertTaskMaster" element={<AlertTaskMaster />} />
          <Route path="/digitalPaaniTeam" element={<DigitalPaaniTeam />} />
          <Route path="/workspaceAlertTaskMaster" element={<WorkspaceAlertTaskMaster />} />
        </Route>
        <Route>
          <Route path="/plants" element={<Plants />} />
        </Route>
        <Route path="/dashboardConfig/:plantId" element={<DashboardConfig />} />
        <Route path="/task-list" element={<TaskList />} />
        <Route path="/checkIn" element={<CheckIn />} />
        <Route path="/expanded-view/:id" element={<ExpandedView />} />
        <Route path="/video-tutorial" element={<VideoTutorial />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/inventory/:id" element={<Inventory />} />
        <Route path="/DataInputs/:plantId" element={<DataInputs />} />
        <Route path="/tickets" element={<Ticketsv2 />} />
        <Route path="/workforce" element={<WorkForce />} />
        <Route path="/escalation/:id" element={<EscalationLevel />} />
        {/* <Route path="/reports/:id" element={<Reports />} /> */}
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/manpowerAvailability" element={<ManpowerAvailability />} />
        <Route path="/checkInForRO" element={<CheckInForRO />} />
        <Route path="/oneTimeTask/:id" element={<OneTimeTask />} />
        <Route path="/maintenance/:id" element={<Maintenance />} />
        <Route path="/equipmentControl/:id" element={<EquipmentControl />} />
        {/* <Route path="/reports2/:id" element={<Reports2 />} /> */}
        <Route path="/dashboardPage/:plantId" element={<DashboardPage />} />
        {/* <Route path="/UnifiedDashboardPage/:pageId" element={<UnifiedDashboardPage />} /> */}
        <Route path="/UnifiedDashboardPage/:pageId" element={<UnifiedDashboardPage2 />} />
        <Route path="/pageConfig/:plantId/:pageId" element={<PageConfig />} />
        <Route
          path="/emailPage/:plantId/:pageId/:days/:startDateUrl/:endDateUrl/:granularity"
          element={<EmailPage />}
        />
        <Route
          path="/dashboardA4PrintPage/:plantId/:pageId/:days/:startDateUrl/:endDateUrl/:granularity"
          element={<DashboardA4PrintPage />}
        />
        <Route path="/data-inputv2/:plantId" element={<DataInputv2 />} />
        <Route path="/sensors/:plantId" element={<Sensors />} />
        <Route path={'aboutPage'} element={<AboutPage />} />
        <Route path="/Chemicals/:plantId" element={<InventoryCategoryLogs />} />
        <Route path="/Consumables/:plantId" element={<InventoryCategoryLogs />} />
        <Route path="/ElectricalSpare/:plantId" element={<InventoryCategoryLogs />} />
        <Route path="/MechanicalSpare/:plantId" element={<InventoryCategoryLogs />} />
        <Route path="/Tools/:plantId" element={<InventoryCategoryLogs />} />
        <Route path="/ToolForCleaning/:plantId" element={<InventoryCategoryLogs />} />
        <Route path="/ChemicalLogs" element={<ChemicalLog />} />
        <Route path="/SolutionDetailsForChemical" element={<SolutionDetailsForChemical />} />
        <Route path="/PlantVisualisation/:plantId" element={<PlantVisualisationParent />} />
        <Route path="/WelspunDemo/:assetId" element={<WelspunDemo />} />
        <Route path="/BiDirectionalLogs/:plantId" element={<BiDirectionalLogs />} />
        <Route path={'/DashboardPageList/:plantId'} element={<DashboardPageList />} />
        <Route path={'/UnifiedDashboardPageList'} element={<UnifiedDashboardPageList />} />
        {/* <Route
          path="/InventoryOptions/:plantId"
          element={<InventoryOptions />}
        /> */}
        <Route path="/StoreMaintenance" element={<InventoryOptions />} />
        <Route path="/Solution" element={<Solution />}></Route>
        <Route path="/SolutionDetail" element={<SolutionDetail />} />
        <Route path="/InventoryOptions/:id" element={<InventoryOptions />} />
        <Route path="/ChemicalComposition/:plantId" element={<ChemicalComposition />} />
        <Route path="/ChemicalComposition/:plantId" element={<ChemicalComposition />} />
        <Route path="/pageConfigv2/:plantId/:pageId" element={<PageConfigV2 />} />
        <Route path="/UnifiedPageConfig/:pageId" element={<UnifiedPageConfig />} />
        <Route path="/DnDUnifiedPageConfig/:pageId" element={<DnDUnifiedPageConfig />} />
        <Route path="/TestPage" element={<TestPage />} />
        <Route path="/ForgotPasswordV2" element={<ForgotPasswordV2 />} />
        <Route path="/WorkspaceConfig/:workspaceId" element={<WorkspaceConfig />} />
        <Route path="/WorkspaceConfigList" element={<WorkspaceConfigList />} />
        <Route path="/StoreConfiguration" element={<StoreConfiguration />} />
        <Route path="/StoreDetails" element={<StoreDetails />} />
        <Route path="/Data-Correction" element={<DataCorrection />} />
        <Route
          path="/LayoutVisualisationConfig/:workspaceId"
          element={<LayoutVisualisationConfig />}
        />
        <Route path="/LayoutVisualisation/:assetId" element={<LayoutVisualisationParent />} />
        <Route path="/AIAnalysisConfig" element={<AIAnalysisConfig />} />
        <Route path="/formula-list" element={<Suspense fallback={<div>Loading...</div>}><FormulaListing /></Suspense>} />
        <Route path="/formula-creation/:formulaId?" element={<Suspense fallback={<div>Loading...</div>}>
          <FormulaCreation />
        </Suspense>} />
        {/* Protected Routes */}
        {/* 404 route */}
        <Route path="*" element={<Page404 />} />
      </Routes>

      {show ? (
        <ReactNotificationComponent title={notification.title} body={notification.body} />
      ) : null}
      <Notifications />
      <ToastContainer />
    </>
  );
}

export default App;
